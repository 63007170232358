@font-face {
  font-family: 'gotham';
  src: url('./fonts/gotham_light_0.OTF');
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active{
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

#panolens-container div:nth-child(3){
  display:none
}
#panolens-container div:nth-child(3) span:first-child{
  bottom: -200%!important;
}
#panolens-container div:nth-child(3) span:last-child {
  bottom: -300%!important;
}

.MuiListItemIcon-root{
  min-width: 32px!important;
}